<template>
  <v-container v-if="vueProdutoClassificacoes">
    <v-row>
      <v-col cols="12">
        <PageToolbar title="Classificacoes" icon="bookmark" voltar dark />
      </v-col>

      <v-col cols="12">
        <v-card>
          <v-card-title class="subtitle-1 lightgray">
            <v-spacer></v-spacer>
            <v-btn color="buttons" depressed dark @click="criarClassificacao">
              Classificação
              <v-icon right>add</v-icon>
            </v-btn>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-data-table
              :headers="headers"
              :items="classificacoes"
              :items-per-page="10"
              :sort-by="['id']"
              class="data-tables "
              :class="{ 'data-tables__row-click': vueEditProdutoClassificacao }"
              @click:row="goToClassificacao"
            >
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="dialog" max-width="290">
      <v-card :loading="loading" :disabled="loading">
        <v-card-title class="lightgray gray--text subtitle-1 px-4">
          {{ editar ? "Editar" : "Nova" }} Classificação
          <v-spacer></v-spacer>
          <v-btn icon @click="dialog = false"><v-icon>close</v-icon></v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="py-6">
          <v-text-field
            v-model="classificacao.descricao"
            label="Descrição"
            dense
            filled
            hide-details
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            small
            color="anchors"
            depressed
            dark
            v-if="editar"
            @click="updateClassificacao"
          >
            Salvar
          </v-btn>
          <v-btn
            small
            color="anchors"
            depressed
            dark
            v-else-if="!editar"
            @click="createClassificacao"
          >
            Criar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
  <NaoAutorizado v-else />
</template>

<script>
import { mapGetters } from "vuex";

import {
  getAllProClass,
  postProClass,
  putProClass,
} from "@/services/api/produtos.api.js";
export default {
  name: "ProdutoClassificacoes",
  props: {},
  data() {
    return {
      classificacoes: [],
      classificacao: {},
      loading: false,
      dialog: false,
      editar: false,
    };
  },
  computed: {
    headers() {
      return [
        {
          text: "ID",
          value: "id",
        },
        {
          text: "Descrição",
          value: "descricao",
        },
      ];
    },
    ...mapGetters("Componentes", ["getAccess"]),
    vueProdutoClassificacoes() {
      return this.getAccess("vueProdutoClassificacoes", "menu");
    },
    vueEditProdutoClassificacao() {
      return this.getAccess("vueProdutoClassificacao", "editar");
    },
  },
  methods: {
    goToClassificacao(item) {
      if (this.vueEditProdutoClassificacao) {
        this.classificacao = item;
        this.editar = true;
        this.dialog = true;
      }
    },
    criarClassificacao() {
      this.classificacao = {};
      this.editar = false;
      this.dialog = true;
    },
    getProClass() {
      getAllProClass()
        .then((response) => {
          this.classificacoes = response;
          this.$Progress.finish();
        })
        .catch((error) => {
          console.log(error);
          this.$Progress.fail();
        });
    },
    createClassificacao() {
      this.loading = true;
      postProClass(this.classificacao)
        .then((response) => {
          if (response.status === 201) {
            this.$toast.success("Classificacao criada com sucesso!");
            this.classificacao = {};
            this.getProClass();
            this.dialog = false;
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    updateClassificacao() {
      this.loading = true;
      putProClass(this.classificacao.id, this.classificacao)
        .then((response) => {
          if (response.status === 201) {
            this.$toast.success("Classificacao atualizada com sucesso!");
            this.dialog = false;
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  beforeCreate() {
    this.$Progress.start();
  },
  mounted() {
    this.getProClass();
  },
};
</script>

<style lang="scss" scoped></style>
